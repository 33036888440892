import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollToTopComponent } from './scroll-to-top.component';

@NgModule({

  imports: [
    CommonModule
  ],
  exports: [
    ScrollToTopComponent
  ],
  declarations: [ 
    ScrollToTopComponent
  ],
  providers: []
})
export class ScrollToTopModule { }
