import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invalid-form-dialog',
  templateUrl: './invalid-form-dialog.component.html',
  styleUrls: ['./invalid-form-dialog.component.scss']
})
export class InvalidFormDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<InvalidFormDialogComponent>
  ) {
  }

  ngOnInit(): void {
  }

}
