<div class="container p-4">
    <div class="row pb-3 pl-3">
        <img src="../../../../assets/img/webp/icon-safe-dark.webp" alt="{{ 'seo.iconSafeDark' | translate }}">
        <h1 class="px-3 d-flex align-items-end">{{ 'appDialog.safeTitle' | translate }}</h1>
    </div>
    <div class="row pb-3 pl-3">
        <p>
            {{ 'appDialog.safeHeader' | translate }}
        </p>
    </div>
    <div class="row">
        <ul>
            <li>{{ 'appDialog.safeList1' | translate }}</li>
            <li>{{ 'appDialog.safeList2' | translate }}</li>
            <li>{{ 'appDialog.safeList3' | translate }}</li>
        </ul>
    </div>
</div>