<nav [class.shrink]="scrollTop" id="menu" class="navbar fixed-top navbar-expand-xl navbar-light bg-light">
  <a class="navbar-brand d-flex" href="#">
    <img class="big-logo" src="/assets/img/webp/foto-gis-logo.webp" height="70"
      alt="{{ 'seo.fotoGisLogo' | translate }}">
    <img class="small-logo" src="/assets/img/webp/foto-gis-brand-logo.webp"
      alt="{{ 'seo.fotoGisBrandLogo' | translate }}" height="40">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto" id="navabar">
      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" href="https://pro.foto-gis.eu/" target="_blank">{{ 'menu.test' | translate }}</a>
      </li>
      <li class="nav-item link1" data-toggle="collapse" data-target=".navbar-collapse.show"
        (click)="scrollToDocuments('event')">
        <div [routerLink]="['']">
          <a class="nav-link">{{ 'menu.download' | translate }}</a>
        </div>
      </li>
      <li class="nav-item link1" data-toggle="collapse" data-target=".navbar-collapse.show"
        (click)="scrollToReference('event')">
        <div [routerLink]="['']">
          <a class="nav-link">{{ 'menu.reference' | translate }}</a>
        </div>
      </li>
      <li class="nav-item link1" data-toggle="collapse" data-target=".navbar-collapse.show"
        (click)="scrollToAboutUs('event')">
        <div [routerLink]="['']">
          <a class="nav-link">{{ 'menu.about' | translate }}</a>
        </div>
      </li>
      <li class="nav-item link1" data-toggle="collapse" data-target=".navbar-collapse.show"
        (click)="scrollToContact('event')">
        <div [routerLink]="['']">
          <a class="nav-link">{{ 'menu.contact' | translate }}</a>
        </div>
      </li>
      <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" [routerLink]="['/pricing']"><img src="../../assets/img/webp/icon-shop.webp"
            alt="{{ 'seo.iconShop' | translate }}" width="40px"></a>
      </li>
      <li class="nav-item dropdown dropleft">
        <a aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" class="dropdown-toggle"
          href="#">
          <span id="selected"></span><span class="caret"></span></a>
        <ul class="dropdown-menu">
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <span class="nav-link lang px-4" (click)="setLang('en')">EN</span>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <span class="nav-link lang px-4" (click)="setLang('pl')">PL</span>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <span class="nav-link lang px-4" (click)="setLang('de')">DE</span>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <span class="nav-link lang px-4" (click)="setLang('es')">ES</span>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <span class="nav-link lang px-4" (click)="setLang('fr')">FR</span>
          </li>
        </ul>
      </li>

    </ul>
  </div>
</nav>