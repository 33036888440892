import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DOCUMENT } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  scrollTop = false;

  constructor(
    private translate: TranslateService,
    private storage: StorageMap,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    $('#selected').text(localStorage.getItem('lang').toUpperCase());

    $('.dropdown-menu span').click(function () {
      $('#selected').text($(this).text());
    });
  }
  test() {
    $('.navbar-collapse ul li a:not(.dropdown-toggle)').bind('click touchstart', function () {
      $('.navbar-toggle:visible').click();
    });
  }
  
  scrollToPresentation(event) {
    setTimeout(() => {
      document.getElementById("presentation").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('presentation').offsetTop - 80);
      }
    }, 100);
  }

  scrollToAdvise(event) {
    setTimeout(() => {
      document.getElementById("advise").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('advise').offsetTop - 80);
      }
    }, 100);
  }

  scrollToSystemTrio(event) {
    setTimeout(() => {
      document.getElementById("system-trio").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('system-trio').offsetTop - 80);
      }
    }, 100);
  }

  scrollToDocuments(event) {
    setTimeout(() => {
      document.getElementById("documents").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('documents').offsetTop - 80);
      }
    }, 100);
  }

  scrollToReference(event) {
    setTimeout(() => {
      document.getElementById("reference").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('reference').offsetTop - 80);
      }
    }, 100);
  }

  scrollToAboutUs(event) {
    setTimeout(() => {
      document.getElementById("aboutUs").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('aboutUs').offsetTop - 80);
      }
    }, 100);
  }

  scrollToContact(event) {
    setTimeout(() => {
      document.getElementById("contact").scrollIntoView({ behavior: 'smooth'});
      event.preventDefault();
      let isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
      if (isSafari) {
        window.scrollTo(0, document.getElementById('contact').offsetTop - 80);
      }
    }, 100);
  }

  setLang(lang) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    this.storage.set('langSubs', lang).subscribe(() => { });
  }

  getLang() {
    return localStorage.getItem('lang');
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20) {
      this.scrollTop = true;
    }
    else {
      this.scrollTop = false;
    }
  }
}