<div class="container p-4">
    <div class="row pb-3 pl-3">
        <img src="../../../../assets/img/webp/icon-organizer-dark.webp" alt="{{ 'seo.iconOrganizerDark' | translate }}">
        <h1 class="px-3 d-flex align-items-end">{{ 'appDialog.organizerTitle' | translate }}</h1>
    </div>
    <div class="row pb-3 pl-3">
        <p>
            {{ 'appDialog.organizerHeader' | translate }}
        </p>
    </div>
    <div class="row">
        <ul>
            <li>{{ 'appDialog.organizerList1' | translate }}</li>
            <li>{{ 'appDialog.organizerList2' | translate }}</li>
            <li>{{ 'appDialog.organizerList3' | translate }}</li>
            <li>{{ 'appDialog.organizerList4' | translate }}</li>
            <li>{{ 'appDialog.organizerList5' | translate }} 
                <button mat-raised-button (click)="openModalContact()">{{ 'appDialog.organizerOpenDialogButton' | translate }}</button>
            </li>
        </ul>
    </div>
</div>