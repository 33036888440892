<div class="container p-4">
    <div class="row pb-3 pl-3">
        <img src="../../../../assets/img/webp/icon-app-dark.webp" alt="{{ 'seo.iconAppDark' | translate }}">
        <h1 class="px-3 d-flex align-items-end">{{ 'appDialog.appTitle' | translate }}</h1>
    </div>
    <div class="row pb-3 pl-3">
        <p>
            {{ 'appDialog.appHeader' | translate }}
        </p>
    </div>
    <div class="row">
        <ul>
            <li>{{ 'appDialog.appList1' | translate }}</li>
            <li>{{ 'appDialog.appList2' | translate }}</li>
            <li>{{ 'appDialog.appList3' | translate }}</li>
            <li>{{ 'appDialog.appList4' | translate }}</li>
            <li>{{ 'appDialog.appList5' | translate }}</li>
            <li>{{ 'appDialog.appList6' | translate }}</li>
            <li>{{ 'appDialog.appList7' | translate }}</li>
        </ul>
    </div>
</div>