<div class="contact-form px-5 pt-5">
    <h1 class="w-100 d-flex justify-content-center">{{ 'contactForm.header' | translate }}</h1>
    <form [formGroup]="userForm" method="POST" class="m-auto">
        <div class="form-group">
            <input
                [class.red]="userForm.controls['name'].invalid && (userForm.controls['name'].dirty || userForm.controls['name'].touched)"
                id="name" type="text" name="name" #refNom class="form-control" formControlName="name"
                placeholder="{{ 'contactForm.name' | translate }}">
        </div>
        <div class="form-group">
            <input
                [class.red]="userForm.controls['surname'].invalid && (userForm.controls['surname'].dirty || userForm.controls['surname'].touched)"
                id="surname" type="text" name="surname" #refPrenom class="form-control" formControlName="surname"
                placeholder="{{ 'contactForm.surname' | translate }}" required>
        </div>
        <div class="form-group">
            <input
                [class.red]="userForm.controls['company'].invalid && (userForm.controls['company'].dirty || userForm.controls['company'].touched)"
                id="company" type="text" name="company" #refPrenom class="form-control" formControlName="company"
                placeholder="{{ 'contactForm.company' | translate }}">
        </div>
        <div class="form-group">
            <input
                [class.red]="userForm.controls['email'].invalid && (userForm.controls['email'].dirty || userForm.controls['email'].touched)"
                id="email" type="email" name="email" class="form-control" formControlName="email"
                placeholder="{{ 'contactForm.email' | translate }}" ngModel email required>
        </div>
        <div class="form-group">
            <input
                [class.red]="userForm.controls['telephone'].invalid && (userForm.controls['telephone'].dirty || userForm.controls['telephone'].touched)"
                id="telephone" type="text" class="form-control" name="telephone" formControlName="telephone"
                placeholder="{{ 'contactForm.telephone' | translate }}" required>
        </div>
        <div class="form-group">
            <textarea
                [class.red]="userForm.controls['message'].invalid && (userForm.controls['message'].dirty || userForm.controls['message'].touched)"
                id="message" class="form-control" name="message" id="exampleTextarea" rows="3" formControlName="message"
                placeholder="{{ 'contactForm.message' | translate }}"></textarea>
        </div>
        <div class="d-flex justify-content-center">
            <re-captcha formControlName="captcha" name="captcha" required
                siteKey="6Lcm5BkUAAAAAPrWPvgimZrNJHX-Kqhu-OHLyiId">
            </re-captcha>
        </div>
        <div class="d-flex justify-content-center align-items-center personal-data-container">
            <mat-checkbox name="personalData" [(ngModel)]="consentPersonalData" [ngModelOptions]="{standalone: true}">
            </mat-checkbox>
            <div class="personal-data-text"> {{'contactForm.consentPersonalData' | translate }}</div>
        </div>
        <div class="row d-flex justify-content-around pt-4 px-4 pb-5">
            <button class="btn btn--cancel" (click)="closeContactPopup()">{{ 'contactForm.cancel' | translate
                }}</button>
            <button class="btn btn--send" type="submit" (click)="onSubmit()"
                [disabled]="userForm.invalid || !consentPersonalData">{{
                'contactForm.submit' | translate }}</button>
        </div>
    </form>
</div>