import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AgbComponent } from './agb/agb.component';
import { PriceComponent } from './price/price.component';
import { ReleaseNoteComponent } from './release-note/release-note.component';
import { PrivacyComponent } from './privacy/privacy.component'
import { TermsAndConditionsCustomerCashbackComponent } from './terms-and-conditions-customer-cashback/terms-and-conditions-customer-cashback.component';

const routerOptions: ExtraOptions = {
  scrollOffset: [0, 50],
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('./price/price.module').then(m => m.PriceModule)
  },
  {
    path: 'agb',
    loadChildren: () => import('./agb/agb.module').then(m => m.AgbModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then(m => m.ImpressumModule)
  },
  {
    path: 'release-note',
    loadChildren: () => import('./release-note/release-note.module').then(m => m.ReleaseNoteModule)
  },
  {
    path: 'terms-and-conditions-customer-cashback',
    loadChildren: () => import('./terms-and-conditions-customer-cashback/terms-and-conditions-customer-cashback.module').then(m => m.TermsAndConditionsCustomerCashbackModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
