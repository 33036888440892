import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InvalidFormDialogComponent } from '../invalid-form-dialog/invalid-form-dialog.component';
export interface FormModel {
  captcha?: string;
}
@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {

  name: string;
  surname: string;
  company: string;
  email: string;
  message: string;
  endpoint: string;
  telephone: string;
  consentPersonalData: boolean = false;

  public formModel: FormModel = {};

  constructor(private http: HttpClient,
    public matDialog: MatDialog,
    public dialogRef: MatDialogRef<ContactDialogComponent>) {
    this.http = http;
  }

  userForm = new FormGroup({
    name: new FormControl(null),
    surname: new FormControl(null, Validators.pattern(/[\S]/)),
    company: new FormControl(null),
    email: new FormControl(null),
    telephone: new FormControl(null, Validators.pattern(/[\S]/)),
    message: new FormControl(null),
    captcha: new FormControl(null)
  });

  ngOnInit() {
    this.endpoint = "https://www.foto-gis.eu/assets/php/sendEmail.php";

    setTimeout(function () {
      let reChapta = document.querySelector('.g-recaptcha-bubble-arrow').parentElement;
      reChapta.classList.add('posFix');
      console.log(reChapta)
    }, 3000);
  }

  onSubmit() {
    if (this.userForm.invalid || !this.consentPersonalData) {
      this.matDialog.open(InvalidFormDialogComponent)
    } else {
      this.endpoint = "https://www.foto-gis.eu/assets/php/sendEmail.php";
      this.http.post(this.endpoint, this.userForm.value)
        .subscribe(
          response => {
            console.log("res: " + response)
            this.userForm.reset();
            this.consentPersonalData = false;
            this.closeContactPopup();
          },
          error => console.log(error)
        )
    }
  }

  @HostListener('keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent) {
    this.closeContactPopup();
  }

  closeContactPopup() {
    this.dialogRef.close();

  }
}


